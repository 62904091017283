import React from "react"
import { graphql } from "gatsby"
import { BlogLayout } from "../components/layouts"
import SEO from "../components/seo"
import { ScrollToButton } from "../components/buttons"
import { PodcastPlayer } from "../components/podcast"
import "./podcast.scss"

const PodcastTemplate = props => {
  const post = props.data.markdownRemark

  return (
    <BlogLayout>
      <SEO
        title={post.frontmatter.titletag}
        ogDescription={post.frontmatter.description}
      />
      <article className="blog-post">
        <h1>{post.frontmatter.title}</h1>
        <ScrollToButton
          id={post.id}
          content="Click To Listen"
          style={{ marginBottom: "20px" }}
        />
        <section
          className="markdown"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        <PodcastPlayer id={post.id} src={post.frontmatter.audio} />
      </article>
    </BlogLayout>
  )
}

export default PodcastTemplate

export const pageQuery = graphql`
  query PodcastBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        titletag
        date
        author
        audio
      }
    }
  }
`
