import React from "react"
import ReactAudioPlayer from "react-audio-player"

export default function PodcastPlayer({ id, src }) {
  return (
    <div id={id}>
      <h3>Listen to the episode</h3>
      <ReactAudioPlayer src={src} controls />
    </div>
  )
}

PodcastPlayer.defaultProps = {
  id: null,
  src: "",
}
